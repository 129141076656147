import { useState, useEffect } from 'react'

const useWindowWidth = () => {
    const [width, setWidth] = useState(0)

    useEffect(() => {
        const updateWidth = () => {
            if (window.innerWidth !== width) {
                setWidth(window.innerWidth)
            }
        }

        updateWidth()
        window.addEventListener('resize', updateWidth)

        return () => window.removeEventListener('resize', updateWidth)
    }, [width])

    return width
}

export default useWindowWidth