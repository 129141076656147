import { useState, useMemo } from 'react'
import NavLinkButton from '@components/shared/NavLinkButton'
import ListOut from '@components/shared/ListOut'
import NavLink from '@components/shared/NavLink'
import Icon from '@components/shared/Icon'
import useT from '@hooks/useTranslation'

import {
    USER_MY_DATA,
    USER_MY_OFFERS,
    USER_MY_BUY_OFFERS,
    USER_MY_PRODUCTS,
    USER_MY_ARTICLES,
    USER_SUBSCRIPTIONS,
    USER_DEALER,
    USER_PARTNER,
    USER_WALLET,
    LOGOUT_PAGE,
} from '@constants/routes'

import styles from 'styles/user/UserNav.module.css'

const isDealer = (user) => Boolean(user.dealer && !user.is_partner)
const isPartner = (user) => Boolean(user.is_partner)
const isNotPartner = (user) => !isPartner(user)
const isDealerOrPartner = (user) => isPartner(user) || isDealer(user)

const links = [
    { t: 'Moje dane', to: USER_MY_DATA },

    { t: 'Moje ogłoszenia', to: USER_MY_OFFERS, condition: isNotPartner },
    { t: 'Moje oferty kupna', to: USER_MY_BUY_OFFERS, condition: isNotPartner },

    { t: 'Moje produkty', to: USER_MY_PRODUCTS, condition: isPartner },
    { t: 'Moje artykuły', to: USER_MY_ARTICLES, condition: isPartner },

    { t: 'Subskrypcje', to: USER_SUBSCRIPTIONS, condition: isDealerOrPartner },

    { t: 'Profil dealera', to: USER_DEALER, condition: isDealer },
    { t: 'Konto partnera', to: USER_PARTNER, condition: isPartner },

    { t: 'Mój portfel', to: USER_WALLET },
    { t: 'Wyloguj ✕', to: LOGOUT_PAGE },
]

const UserNav = ({ user, mobile }) => {
    const [open, setOpen] = useState(false)
    const userLinks = useMemo(() => getUserLinks(user, links), [user?._id])
    return (
        <>
                <Button user={user} onClick={() => setOpen(!open)} />
            { open &&
                <UserMenu mobile={mobile}>
                    <UserLinks links={userLinks} />
                </UserMenu> }
        </>
    )
}

function Button ({ user, onClick }) {
    return (
        <NavLinkButton color="teal" onClick={onClick}>
            <Icon>perm_identity</Icon>
            {useT('Witaj')} {user.firstname || ''}! ▾
        </NavLinkButton>
    )
}

function UserMenu ({ children, mobile }) {
    return <div className={mobile
        ? styles.mobile
        : styles.menu}>{children}</div>
}

function UserLinks ({ links }) {
    return <ListOut id="t" items={links} Component={NavLink} />
}

function getUserLinks (user, links) {
    return links.reduce((acc, link) =>
        (link.condition && !link.condition(user)
            ? acc
            : acc.concat(link)), [])
}

export default UserNav