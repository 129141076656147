import { useMemo } from 'react'
import useWindowWidth from '@hooks/useWindowWidth'

const withBreakpoints =
    (breakpointObject) =>
        (TargetComponent) =>
            (props) => {
                const width = useWindowWidth()
                const breakpoints = useMemo(
                    () => resolveBreakpoints(breakpointObject, width),
                    [width]
                )
                return <TargetComponent {...props}
                    width={width} breakpoints={breakpoints} />
            }

function resolveBreakpoints (object, width) {
    const breakpoints = {}
    Object
        .entries(object)
        .forEach(([name, minWidth]) => {
            breakpoints[name] = (width <= minWidth)
        })
    return breakpoints
}

export default withBreakpoints