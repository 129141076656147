const defAlt = 'Dostawcze'
const defTitle = 'Dostawcze - ogłoszenia'
const logos = {
    light: {
        src: '/logo/dostawczaki.svg',
    },

    dark: {
        src: '/logo/dostawczaki-dark.svg',
    }
}

const Logo = ({ bg = 'light', className = '' }) => {
    const { src, alt = defAlt, title = defTitle} = logos[bg] || {}

    return src
        ? <img width="212" height="62" src={src} alt={alt} title={title} className={className} />
        : null
}

export default Logo