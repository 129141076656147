import Icon from '@components/shared/Icon'
import { useChatUnreadMessages, useToggleChat } from '@components/chat/context/ChatStateContext'
import withUser from '@hoc/withUser'

import styles from '@components/chat/styles/Icon.module.css'

const ChatIcon = () => {
    const unreadMessageCount = useChatUnreadMessages()
    const hasUnreadMessages = Boolean(unreadMessageCount)

    const toggleChat = useToggleChat()

    return (
        <aside className={styles.icon} onClick={toggleChat}>
            <div className={styles.inner}>
                <Icon>chat_bubble_outline</Icon>
                { hasUnreadMessages &&
                    <span>{unreadMessageCount}</span> }
            </div>
        </aside>
    )
}

const Guard = ({ isLoggedIn }) =>
    isLoggedIn
        ? <ChatIcon />
        : null

export default withUser(Guard)
