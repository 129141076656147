import Link from 'next/link'
import useT from '@hooks/useTranslation'

import styles from 'styles/NavLink.module.css'

const NavLink = ({ t, to }) => (
    <Link href={to} prefetch={false}>
        <a className={styles.navlink}>{ useT(t) }</a>
    </Link>
)

export default NavLink