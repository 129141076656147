import UserSessionNav from '@components/session/UserNav'
import Logo from './Logo'
import Link from 'next/link'
import ListOut from './ListOut'
import NavLink from './NavLink'
import NavLinkColor from './NavLinkColor'
// import Lang from '@components/lang/Lang'
import Watched from '@components/links/Watched'
import Maybe from './Maybe'
import Icon from './Icon'
import Chat from '@components/chat/nav/Icon'

import useSWR from 'swr'
import storage from '@utils/localStorage'
import checkLogin from '@utils/checkLogin'

import useWindowScroll from '@hooks/useWindowScroll'
import useHamburger from '@hooks/useHamburger'

import {
    // BUY_CAR_PAGE,
    FIRM_BASE_PAGE,
    NEWS_PAGE,

    ADD_OFFER_PAGE,
    LOGIN_PAGE,
    SALE_DOC_PAGE,
    COLISION_DOC_PAGE,
    SALE_BUY_DOC_PAGE,

    ADD_PARTNER_PRODUCT,
    ADD_PARTNER_ARTICLE,
} from '@constants/routes'

import styles from 'styles/Navbar.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

const links = [
    { t: 'Dostawcze używane', to: '/samochody-dostawcze?stan=Uzywany' },
    // { t: 'Kupię samochód', to: BUY_CAR_PAGE },
    // { t: 'Sprzedaj', to: SELL_PAGE },
    // { t: 'Motocykle', to: '/motocykle' },
    { t: 'Baza firm', to: FIRM_BASE_PAGE },
    { t: 'Informacje motoryzacyjne', to: NEWS_PAGE },
]

const desktopLinks = [
    { t: 'Umowa kupna sprzedaży', to: SALE_DOC_PAGE },
]

const docLinks = [
    ...desktopLinks,
    { t: 'Oświadczenie sprawcy kolizji', to: COLISION_DOC_PAGE },
    { t: 'Zgłoszenie sprzedaży samochodu', to: SALE_BUY_DOC_PAGE },
]

const addOfferLink = { t: 'Dodaj ogłoszenie', to: ADD_OFFER_PAGE }
const addPartnerProductLink = { t: 'Dodaj produkt', to: ADD_PARTNER_PRODUCT }
const addPartnerArticleLink = { t: 'Dodaj artykuł', to: ADD_PARTNER_ARTICLE }
const loginLink = { t: 'Zaloguj się', to: LOGIN_PAGE }

// breakpoints (withBreakpoints hoc):
const Navbar = ({ breakpoints, logoOnly }) => {
    const [y, lastY] = useWindowScroll()
    const visibility = y < 80 || y < lastY
        ? styles.visible
        : styles.hidden

    const { showHamburger } = breakpoints

    return (
        <nav className={`${styles.navbar} ${visibility}`}>
            <Link href={HOST}>
                <a className={styles.logo}><Logo /></a>
            </Link>
            {
                !logoOnly && (showHamburger
                    ? <MobileLayout />
                    : <DesktopLayout />)
            }
        </nav>
    )
}


function MobileLayout () {
    const { data: user } = useSWR('user', storage)
    const isLoggedIn = checkLogin(user)

    const Hamburger = useHamburger()

    return (
        <>
            {/* <Lang /> */}
            <Maybe test={isLoggedIn}>
                <Watched />
            </Maybe>
            <Hamburger />
            <Hamburger.Menu>
                <Maybe test={!isLoggedIn}>
                    <NavLinkColor {...loginLink} color="teal" iconLeft={<Icon>person_outline</Icon>} />
                </Maybe>
                <Maybe test={isLoggedIn}>
                    <UserSessionNav user={user} mobile />
                </Maybe>
                <ListOut id="t" items={links} Component={NavLink} />
                <ListOut id="t" items={docLinks} Component={NavLink} />
                <br />
                <Chat />
            </Hamburger.Menu>
        </>
    )
}

function DesktopLayout () {
    const { data: user } = useSWR('user', storage)
    const isLoggedIn = checkLogin(user)

    return (
        <>
            <ListOut id="t" items={links} Component={NavLink} />
            <ListOut id="t" items={desktopLinks} Component={NavLink} />
            <Chat />
            {/* <Lang /> */}
            <Maybe test={isLoggedIn}>
                <Watched />
            </Maybe>

            <Maybe test={user?.is_partner}>
                <DoubleLink>
                    <NavLinkColor {...addPartnerProductLink} color="blue" iconLeft={<Icon>add</Icon>} half />
                    <NavLinkColor {...addPartnerArticleLink} color="blue" iconLeft={<Icon>add</Icon>} half />
                </DoubleLink>
            </Maybe>
            <Maybe test={!user?.is_partner}>
                <NavLinkColor {...addOfferLink} color="blue" iconLeft={<Icon>add</Icon>} title="Dodaj ogłoszenie" />
            </Maybe>

            <Maybe test={!isLoggedIn}>
                <NavLinkColor {...loginLink} color="teal" iconLeft={<Icon>person_outline</Icon>} title="Zaloguj się na portal ogłoszeń motoryzacyjnych" />
            </Maybe>
            <Maybe test={isLoggedIn}>
                <UserSessionNav user={user} />
            </Maybe>
        </>
    )
}

function DoubleLink ({ children }) {
    return (
        <div>
            {children}
        </div>
    )
}

export default Navbar
export {
    addOfferLink,
    addPartnerProductLink,
    addPartnerArticleLink,
}