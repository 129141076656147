import Link from 'next/link'
import Icon from '@components/shared/Icon'
import Badge from '@components/badge/Badge'

import styles from '@components/links/notify/Notify.module.css'

const Notify = ({ count, link, icon, left, right }) => {
    if (!Boolean(count)) return null

    return (
        <Link href={link}>
            <a className={left
                ? styles.left
                : styles.right}>

                <Icon>{icon}</Icon>
                <Badge count={count} />
            </a>
        </Link>
    )
}

export default Notify