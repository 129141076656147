import { useState, useRef, useEffect } from 'react'
import mix from '@utils/styles/mix'

import styles from 'styles/Hamburger.module.css'

let timeout

const useHamburger = () => {
    const [open, setOpen] = useState(false)
    const ref = useRef(null)

    const toggleOpen = () => {
        clearTimeout(timeout)
        ref.current.classList.toggle(styles.active)
        timeout = setTimeout(() => setOpen(!open), 260)
    }

    useEffect(() => {
        return () => clearTimeout(timeout)
    }, [])

    const Hamburger = () => (
        <button
            ref={ref}
            type="button"
            className={mix([styles.hamburger, styles.spin, open && styles.active])}
            onClick={toggleOpen}>

            <span className={styles.box}>
                <span className={styles.inner}></span>
            </span>
        </button>
    )

    Hamburger.Menu = ({ children }) => {
        return open
            ? <div className={styles.menu}>
                { children }
              </div>

            : null
    }

    return Hamburger
}

export default useHamburger