import Link from 'next/link'
import Icon from '@components/shared/Icon'
import Notify from '@components/links/notify/Notify'
import {
    USER_WATCHED,
    USER_WATCHED_CONFIGURATIONS,
    USER_SUBSCRIPTIONS_RECIEVED_MESSAGES,
} from '@constants/routes'

import withWatchedNotifications from '@components/links/hoc/withWatchedNotifications'

function Watched ({ newConfigs, newSubscriptionMessages }) {
    return (
        <Link href={USER_WATCHED}>
            <a style={{color:'var(--teal)',marginRight:7,position:'relative'}}
                title="Obserwowane">
                <Icon>thumb_up</Icon>

                <Notify
                    count={newConfigs}
                    link={USER_WATCHED_CONFIGURATIONS}
                    icon="youtube_searched_for"
                    left />

                <Notify
                    count={newSubscriptionMessages}
                    link={USER_SUBSCRIPTIONS_RECIEVED_MESSAGES}
                    icon="rss_feed"
                    right />
            </a>
        </Link>
    )
}

export default withWatchedNotifications(Watched)