import withUser from '@hoc/withUser'
import useSWR from 'swr'
import { apiFetcherProtectedPost as apiFetcherProtected } from '@api/apiFetcher'

const API_SUB_MESSAGES = '/api/user/subscriptions/messages/received/count_unread'
const API_CONFIGS = '/api/configsaver/recalculate'
const OPTIONS = { refreshInterval: 6 * 60 * 1000 }

const withWatchedNotifications =
    TargetComponent =>
        withUser(
            function watchedNotifications (props) {
                const { isLoggedIn, user, ...rest } = props

                const shouldFetch = isLoggedIn && user?.token
                const fetcher = shouldFetch && apiFetcherProtected(user.token)

                const { data: messageData, messageError } =
                    useSWR(shouldFetch && API_SUB_MESSAGES, fetcher, OPTIONS)
                const { data: configData, configError } =
                    useSWR(shouldFetch && API_CONFIGS, fetcher, OPTIONS)

                const messageDataLoading = !messageData && !messageError
                const configDataLoading = !configData && !configError
                const loading = messageDataLoading || configDataLoading
                const ok = !loading && !messageError && !configError

                const additionalProps = ok
                    ? {
                        newConfigs: configData?._new || 0,
                        newSubscriptionMessages: messageData?.unread || 0,
                      }

                    : {}

                return <TargetComponent {...rest} {...additionalProps} />
            }
        )

export default withWatchedNotifications